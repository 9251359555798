/* This file is for your main application css. */
body {
  margin: 2px; }

/* LiveView specific classes for your customizations */
.invalid-feedback {
  color: #a94442;
  display: block;
  margin: -1rem 0 2rem; }

.phx-no-feedback.invalid-feedback, .phx-no-feedback .invalid-feedback {
  display: none; }

.phx-click-loading {
  opacity: 0.5;
  transition: opacity 1s ease-out; }

.phx-disconnected {
  cursor: wait; }

.phx-disconnected * {
  pointer-events: none; }

.phx-modal {
  opacity: 1 !important;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }

.phx-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; }

.phx-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold; }

.phx-modal-close:hover,
.phx-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer; }

/* Alerts and form errors */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px; }

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; }

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1; }

.alert p {
  margin-bottom: 0; }

.alert:empty {
  display: none; }

.dice {
  font-size: 2em;
  width: 52px;
  height: 52px;
  float: left;
  margin-right: 40px;
  border: 2px solid darkgrey;
  text-align: center;
  background-image: url("/images/dice.png");
  background-size: 442px 50px;
  border-radius: 5px; }

.value_0 {
  background-position: 0px 0px; }

.value_1 {
  background-position: -65px 0px; }

.value_2 {
  background-position: -130px 0px; }

.value_3 {
  background-position: -195px 0px; }

.value_4 {
  background-position: -260px 0px; }

.value_5 {
  background-position: -325px 0px; }

.value_6 {
  background-position: -390px 0px; }

#out {
  padding: 10px;
  border: 2px solid #DDD;
  margin-right: 30px;
  height: 80px;
  float: left; }
  #out span {
    float: left;
    margin-top: -25px;
    background-color: white; }

#in {
  padding-top: 10px; }

.scorebutton {
  color: green;
  padding: 2px 10px 2px 10px;
  cursor: pointer;
  background-color: #efefef;
  width: 40px;
  margin-left: -10px; }

.strikebutton {
  color: red;
  padding: 2px 10px 2px 10px;
  cursor: pointer;
  background-color: #efefef;
  width: 40px; }

tr:nth-child(even) .strikebutton {
  margin-left: 40px; }

tr:nth-child(odd) .strikebutton {
  margin-left: -50px; }

#scoreboard td, #scoreboard th {
  padding: 0px; }

.active {
  background-color: #34a8eb;
  color: white; }

table#scoreboard {
  table-layout: fixed; }
  table#scoreboard th.player, table#scoreboard th:nth-child(1) {
    padding-left: 5px; }
  table#scoreboard tr:nth-child(8) {
    background-color: #EFEFEF; }

th.player {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.last {
  font-weight: bold;
  color: blue;
  text-decoration: underline; }

@media only screen and (max-width: 600px) {
  main.container {
    padding: 5px !important; }
  #out {
    background-color: lightblue;
    margin-right: 10px; }
  .dice {
    margin-right: 10px; } }

#chat {
  position: fixed;
  bottom: 0;
  width: 100%; }
  #chat div {
    float: left;
    width: 45%; }
  #chat span {
    margin-right: 10px; }
  #chat span:nth-child(3) {
    color: red; }
  #chat span:nth-child(1) {
    color: green; }
  #chat span:nth-child(2) {
    color: blue; }

.kniffel {
  background-color: yellow;
  border: 2px dashed red; }

.throws {
  float: right;
  padding-right: 5px; }
